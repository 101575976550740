import { Children, Component } from 'react';
import { twMerge } from 'tailwind-merge';

import type { ReactElement, ReactNode } from 'react';

interface SplitProps {
  children: ReactElement | ReactElement[];
  className?: string;
}

const LeftColumn = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

const RightColumn = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

export class Split extends Component<SplitProps> {
  static LeftColumn = LeftColumn;
  static RightColumn = RightColumn;
  render() {
    const { children, className } = this.props as SplitProps;
    const childArray = Children.toArray(children) as ReactElement[];
    const leftColumn = childArray?.find(
      (child: ReactElement) => child?.type === LeftColumn
    );
    const rightColumn = childArray?.find(
      (child: ReactElement) => child?.type === RightColumn
    );
    return (
      <div
        className={twMerge(
          'w-full flex flex-col-reverse md:block relative overflow-hidden',
          className
        )}
        data-testid="split-layout"
      >
        <main id="main-content" className="md:h-screen flex overflow-auto">
          <div className="w-full md:w-2/3 xl:w-7/12 my-auto flex justify-center px-4 sm:px-8 md:px-16 md:pt-12 pb-12">
            <div className="w-full max-w-xl">
              {leftColumn ? leftColumn.props.children : null}
            </div>
          </div>
        </main>
        <div className="md:fixed -z-10 top-0 bottom-0 right-0 md:w-1/3 xl:w-5/12">
          {rightColumn ? rightColumn.props.children : null}
        </div>
      </div>
    );
  }
}

export default Split;
