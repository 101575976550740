import { twMerge } from 'tailwind-merge';

import type { PropsWithChildren } from 'react';

interface CenteredProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
  mainClassName?: string;
}

export const Centered = ({ children, className }: CenteredProps) => {
  return (
    <div
      className={twMerge('w-full min-h-screen flex flex-col', className)}
      data-testid="main-layout"
    >
      <main
        id="main-content"
        className={twMerge(
          'mx-auto max-w-3xl p-4 flex-1 flex flex-col justify-center'
        )}
      >
        {children}
      </main>
    </div>
  );
};

export default Centered;
