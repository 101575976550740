import { useT } from 'i18n';

const ONBOARDING_IMAGE_SM =
  'https://imagedelivery.net/41eGBrMIml4goV0L9eI4Ww/ff4ce313-b835-4b7c-0f28-0ccd93e80400/fit=scale-down';
const ONBOARDING_IMAGE_MD =
  'https://imagedelivery.net/41eGBrMIml4goV0L9eI4Ww/d450491c-3f5f-4edf-16eb-3eb5ebde6100/fit=scale-down';
const ONBOARDING_IMAGE_XL =
  'https://imagedelivery.net/41eGBrMIml4goV0L9eI4Ww/f8c20b35-a0ba-4e33-5fca-e7a52102c900/fit=scale-down';

export const OnboardingImage = ({ className }: { className?: string }) => {
  const t = useT('translation', 'onboarding');
  return (
    <picture className={className}>
      <source srcSet={ONBOARDING_IMAGE_XL} media="(min-width: 1280px)" />
      <source srcSet={ONBOARDING_IMAGE_MD} media="(min-width: 768px)" />
      <img
        src={ONBOARDING_IMAGE_SM}
        alt={t('productImageAltText')}
        className="w-full h-full object-cover object-left"
      />
    </picture>
  );
};

export default OnboardingImage;
